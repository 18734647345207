const EmailJsConfig = {
  // Tabrez's Gmail
    SERVICE_ID: "service_ymz0yfa",
    TEMPLATE_ID: "template_23izwf7",
    USER_ID: "OZI9PfmDHifIjSIvB",
  // for Contact us page
    TEMPLATE_ID2: "template_6de17fp",

  // //   Naresh's Gmail
  // SERVICE_ID: "service_ymz0yfa",
  // TEMPLATE_ID: "template_6de17fp",
  // USER_ID: "OZI9PfmDHifIjSIvB",
  // // for Contact us page
  // TEMPLATE_ID2: "template_23izwf7",
};

export default EmailJsConfig;
